import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Button } from './components/styles/Button.styled';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import type { Address, Utilities } from './types';
import { fetchAddress, fetchUtility } from './api/api';
import { Dropdown } from 'react-bootstrap';
import { ChooseDataSourceModal } from './components/choose-data-source-modal';

// Move interface outside of component file
export interface ValidateAddressProps {
  btnText: string;
  btnColor: string;
}

export const ValidateAddress = ({ btnText, btnColor }: ValidateAddressProps) => {
  const { t } = useTranslation('homepage');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [addressOptions, setAddressOptions] = useState<Address[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<Address[]>([]);
  const [showDataSourceModal, setShowDataSourceModal] = useState(false);
  const [cookies, , removeCookie] = useCookies(['consent_id']);
  const [loadingUtility, setLoadingUtility] = useState(false);
  const [utility, setUtility] = useState<Utilities[]>([]);
  const [selectedUtility, setSelectedUtility] = useState<Utilities | undefined>(undefined);

  useEffect(() => {
    const savedAddress = localStorage.getItem('saved_address');
    if (savedAddress) {
      fetchAddress(savedAddress).then(data => {
        if (data && data.length > 0) {
          setSelectedAddress(data);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (selectedAddress.length === 0) return;
    setLoadingUtility(true);
    const { zipcode } = selectedAddress[0];
    fetchUtility(zipcode).then(res => {
      setLoadingUtility(false);
      if (res) {
        setUtility(res);
        setSelectedUtility(res[0]);
      }
    });
  }, [selectedAddress]);

  const handleSearch = (val: string) => {
    setLoading(true);
    fetchAddress(val).then(data => {
      setAddressOptions(data);
      setLoading(false);
    });
  };

  const handleClick = () => {
    if (selectedAddress.length === 0) return;
    const address = selectedAddress[0];
    localStorage.setItem('saved_address', address.street);
    setShowDataSourceModal(true);
  };

  const redirectTo = (type: string) => {
    const address = selectedAddress[0];
    const { street, esiid, zipcode, city, state } = address;
    const utilityCode = selectedUtility ? selectedUtility.utility_code : '';
    if (type === 'plan') {
      if (cookies) {
        removeCookie('consent_id');
      }
      navigate(`/plans?address=${encodeURIComponent(street)}&esiid=${esiid}&zipcode=${zipcode}&city=${city}&state=${state}&utcode=${utilityCode}`);
    } else {
      navigate(`/get_usage?address=${encodeURIComponent(street)}&esiid=${esiid}&zipcode=${zipcode}&city=${city}&state=${state}&source=${type}&utcode=${utilityCode}`);
    }
  };

  const chooseUtility = () => {
    if (utility.length <= 1 || !selectedUtility) return null;
    return (
      <Dropdown className='utility-select'>
        <Dropdown.Toggle variant='light'>{selectedUtility.utility_name}</Dropdown.Toggle>
        <Dropdown.Menu>
          {utility.map((val, idx) => (
            <Dropdown.Item
              eventKey={idx}
              key={idx}
              onClick={() => {
                setSelectedUtility(val);
              }}
              active={val.utility_id === selectedUtility.utility_id}>
              {val.utility_name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return (
    <div>
      <div className='row g-3 justify-content-start align-items-center mt-5 mb-0'>
        <div className='col-lg-8 col-12 mt-0 mb-3 mb-lg-0'>
          {/* Choices */}
          <div className='form-mix-icon-input form-size-lg'>
            <div className='autocomplete-address-row w-100'>
              <div id='autocomplete-wrapper'>
                <AsyncTypeahead filterBy={() => true} id='autocomplete' isLoading={loading} labelKey='label' minLength={1} onSearch={handleSearch} options={addressOptions} placeholder={`${t('Address')}...`} size='lg' onChange={setSelectedAddress} selected={selectedAddress} className='px-3 align-items-center' />
              </div>
              {chooseUtility()}
            </div>
            {/* Icons */}
            <span className='position-absolute top-50 start-0 translate-middle ps-5'>
              <i className='bi bi-geo-alt fs-5' />
            </span>
            {/* <a href='#' className='h6 mb-0 position-absolute top-50 end-0 translate-middle pe-1' /> */}
          </div>
        </div>
        <div className='col-lg-4 col-6 d-grid mt-0 ms-auto'>
          {/* Search */}
          <Button 
            onClick={handleClick} 
            disabled={!selectedAddress[0] || loadingUtility} 
            className={`btn btn-lg fs-6 mb-0 gradient-custom-btn ${btnColor}`}
          >
            {btnText}
          </Button>
        </div>
      </div>
      <ChooseDataSourceModal
        show={showDataSourceModal}
        handleClose={() => {
          setShowDataSourceModal(false);
        }}
        handleRedirect={redirectTo}
        utility={selectedUtility}
      />
    </div>
  );
};
